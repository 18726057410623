export const coutriesList = [
    {
      id: '1',
      countyName: 'USA',
      countryCode: 'sa',
      imageUrl: '/countries/usa.png',
      class: ' coutry-card position-relative m-3 active'
    },
    {
      id: '2',
      countyName: 'Spain',
      countryCode: 'es',
      imageUrl: '/countries/spain.jpeg',
      class: ' coutry-card position-relative m-3'
    },
    {
      id: '3',
      countyName: 'Georgia',
      countryCode: 'ir',
      imageUrl: '/countries/georgia.png',
      class: ' coutry-card position-relative m-3'
    },
    {
      id: '4',
      countyName: 'Dubai',
      countryCode: 'dubai',
      imageUrl: '/countries/dubai.jpeg',
      class: ' coutry-card position-relative m-3'
    },
    {
      id: '5',
      countyName: 'Portugal',
      countryCode: 'fr',
      imageUrl: '/countries/portugal.png',
      class: ' coutry-card position-relative m-3'
    },
    {
      id: '6',
      countyName: 'Spain',
      countryCode: 'sa',
      imageUrl: '/countries/spain.jpeg',
      class: ' coutry-card position-relative m-3'
    },
    {
      id: '7',
      countyName: 'Istanbul',
      countryCode: 'sa',
      imageUrl: '/countries/istanbul.jpeg',
      class: ' coutry-card position-relative m-3'
    },
    {
      id: '8',
      countyName: 'UK',
      countryCode: 'gb',
      imageUrl: '/countries/uk.jpeg',
      class: ' coutry-card position-relative m-3'
    },
    {
      id: '9',
      countyName: 'Canada',
      countryCode: 'kw',
      imageUrl: '/countries/canada.png',
      class: ' coutry-card position-relative m-3'
    },{
      id: '10',
      countyName: 'Thailand',
      countryCode: 'kw',
      imageUrl: '/countries/thailand.png',
      class: ' coutry-card position-relative m-3'
    },{
      id: '11',
      countyName: 'Greece',
      countryCode: 'kw',
      imageUrl: '/countries/greece.png',
      class: ' coutry-card position-relative m-3'
    },{
      id: '12',
      countyName: 'Cyprus',
      countryCode: 'kw',
      imageUrl: '/countries/cyprus.png',
      class: ' coutry-card position-relative m-3'
    }

  ];  