import React from 'react';
import Property from './Property';
import LoadingComponent from './LoadingComponent';

function propertyList(properties) {
  return (<>
      {properties.map((property, index) => (
      <Property key={index} property={property} />
    ))}
  </>)
}
function loadingState(properties) {
  return (<>
    <LoadingComponent />
    <LoadingComponent />
    <LoadingComponent />
  </>)
}

const PropertyList = ({ properties, isLoading }) => {
  return (
    <div className="property-list">
      <div className='property-card'>
          {isLoading ? loadingState() : propertyList(properties)}
          {/* {isLoading ? loadingState() : loadingState()} */}
      </div>
    </div>
  );
};

export default PropertyList;